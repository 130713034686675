import '@scss/app';
import Experience from './Experience/Experience.js';
class App {
    constructor() {
        this.initExperience();
    }
    initExperience() {
        this.experience = new Experience({
            targetElement: document.querySelector('.experience')
        });
    }
}
new App();
