import * as THREE from 'three'
import Time from './Utils/Time.js'
import Sizes from './Utils/Sizes.js'
import Stats from './Utils/Stats.js'

import Resources from './Resources.js'
import Renderer from './Renderer.js'
import Camera from './Camera.js'
import World from './World.js'

import assets from './assets.js'

import Menu from '../ts/Menu.ts'
import Cursor from '../ts/_utils/Cursor.ts'

import GridItems from '../ts/GridItem.ts'
import MovieControl from '../ts/MovieControl.ts'
import Loading from '../ts/Loading.ts'
import Text from '../ts/Text.ts'
import ContactForm from '../ts/ContactForm.ts'
// import InfiniteSlider from '../ts/InfiniteSlider.ts'

export default class Experience
{
    static instance

    constructor(_options = {})
    {
        if(Experience.instance)
        {
            return Experience.instance
        }
        Experience.instance = this

        // Options
        this.targetElement = _options.targetElement

        if(!this.targetElement)
        {
            console.warn('Missing \'targetElement\' property')
            return
        }

        this.time = new Time()
        this.sizes = new Sizes()
        this.setConfig()
        this.setStats()
        this.setScene()
        this.setCamera()
        this.setRenderer()
        this.setResources()
        this.setWorld()

        this.setIndex()
        
        this.sizes.on('resize', () =>
        {
            this.resize()
        })

        this.update()
    }

    setConfig()
    {
        this.config = {}
    
        // Debug
        this.config.debug = window.location.hash === '#debug'

        // Pixel ratio
        this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2)

        // Width and height
        const boundings = this.targetElement.getBoundingClientRect()
        this.config.width = boundings.width
        this.config.height = boundings.height || window.innerHeight
    }

    setStats()
    {
        if(this.config.debug)
        {
            this.stats = new Stats(true)
        }
    }
    
    setScene()
    {
        this.scene = new THREE.Scene()
    }

    setCamera()
    {
        this.camera = new Camera()
    }

    setRenderer()
    {
        this.renderer = new Renderer({ rendererInstance: this.rendererInstance })

        this.targetElement.appendChild(this.renderer.instance.domElement)
    }

    setResources()
    {
        this.resources = new Resources(assets)
    }

    setWorld()
    {
        this.world = new World()
    }

    setIndex()
    {
        const cursor = document.querySelector('.cursor')
        const anchors = document.querySelectorAll('a')
        const previewAnchors = document.querySelectorAll('.js-preview')

        previewAnchors.forEach(element => {
            this.gridItems = new GridItems(element)
        })
        this.menu = new Menu()
        this.cursor = new Cursor(cursor)
        this.movieControl = new MovieControl()
        this.loading = new Loading()
        this.text = new Text()
        this.contactForm = new ContactForm()

        anchors.forEach(anchor => {
            anchor.addEventListener('mouseover', () => {
                this.cursor.enter()
            })
            anchor.addEventListener('mouseout', () => {
                this.cursor.leave()
            })
        })
    }

    update()
    {
        if(this.stats)
            this.stats.update()
        
        this.camera.update()

        if(this.world)
            this.world.update(this.time.elapsed)
        
        if(this.renderer)
            this.renderer.update()
        
        if(this.cursor)
            this.cursor.update()
        if(this.loading)
            this.loading.update()

        window.requestAnimationFrame(() =>
        {
            this.update()
        })
    }

    resize()
    {
        // Config
        const boundings = this.targetElement.getBoundingClientRect()
        this.config.width = boundings.width
        this.config.height = boundings.height

        this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2)

        if(this.camera)
            this.camera.resize()

        if(this.renderer)
            this.renderer.resize()

        if(this.world)
            this.world.resize()
    }

    destroy()
    {
        
    }
}
