'use strict';
export default class Mouse {
    constructor() {
        if (Mouse.instance)
            return Mouse.instance;
        this.x = 0;
        this.y = 0;
        Mouse.instance = this;
        this.init();
    }
    static getInstance() {
        if (!Mouse.instance) {
            Mouse.instance = new Mouse();
        }
        return Mouse.instance;
    }
    init() {
        document.addEventListener('mousemove', this.onMouseMove.bind(this));
    }
    onMouseMove(e) {
        this.x = e.clientX;
        this.y = e.clientY;
    }
}
