import { gsap } from 'gsap';
import Preview from './Preview';
import { focusToButton, modalFocus } from './_utils/modalFocus';
export default class GridItem {
    constructor(el) {
        this.hiddenElements = [];
        this.mediaQuery = window.matchMedia('(max-width: 34.375rem)');
        window.addEventListener('DOMContentLoaded', () => {
            this.DOM = { el: el };
            this.DOM.image = this.DOM.el.querySelector('.movie-grid__image');
            this.DOM.content = document.querySelector(`${this.DOM.el.href.substring(this.DOM.el.href.lastIndexOf('#'))}`);
            if (this.DOM.content) {
                this.preview = new Preview(this.DOM.content);
                this.init();
                this.setEventListener();
            }
        });
    }
    init() {
        this.movieItems = document.querySelectorAll('.js-image-float');
        this.movieGridWrapper = document.querySelector('.movie-grid__wrapper');
        this.closeControl = this.DOM.content.querySelector('.preview__item-close');
        this.imageWrapper = this.DOM.content.querySelector('.preview__imageWrapper ');
        if (this.imageWrapper) {
            this.image = this.imageWrapper.querySelector('.preview__image');
            this.video = this.imageWrapper.querySelector('.preview__video');
        }
        this.title = this.DOM.content.querySelector('.preview__title');
        this.genre = this.DOM.content.querySelector('.preview__genre');
        this.members = this.DOM.content.querySelector('.preview__members');
        this.loopTop1 = document.querySelector('.artwork-loop__list-top1');
        this.loopTop2 = document.querySelector('.artwork-loop__list-top2');
        this.loopBottom1 = document.querySelector('.artwork-loop__list-bottom1');
        this.loopBottom2 = document.querySelector('.artwork-loop__list-bottom2');
        this.contactFormTitle = document.querySelector('.contact-form__title-wrapper');
        this.contactFormForm = document.querySelector('#form');
        this.hiddenElements = [];
        const hiddenElementClasses = ['.header', '.side-navigation ', '.wrapper', '.footer'];
        hiddenElementClasses.forEach((className) => {
            this.hiddenElements.push(document.querySelector(className));
        });
    }
    setEventListener() {
        if (this.DOM.el.hash.match(/art\d\d?/)) {
            this.DOM.el.addEventListener('click', e => {
                e.preventDefault();
                this._showArtwork();
            });
            this.closeControl.addEventListener('click', e => {
                e.preventDefault();
                this._closeContent();
            });
        }
        else if ((this.DOM.el.hash.match(/contact-form/))) {
            this.DOM.el.addEventListener('click', e => {
                e.preventDefault();
                this._showContactForm();
            });
            this.closeControl.addEventListener('click', e => {
                e.preventDefault();
                this._closeContent();
            });
        }
        else {
            this.DOM.el.addEventListener('click', e => {
                e.preventDefault();
                this._showMovie();
            });
            this.closeControl.addEventListener('click', e => {
                e.preventDefault();
                this._closeContent();
            });
        }
    }
    _focusHandle(event) {
        modalFocus(event, this.DOM.content, this._closeContent);
    }
    _showMovie() {
        const showTl = gsap.timeline();
        showTl.to(this.movieGridWrapper, { duration: 0.5, opacity: 0, y: -120, ease: 'power4.in' }, 0);
        showTl.to(this.DOM.content, { onComplete: () => {
                var _a;
                (_a = this.DOM.content) === null || _a === void 0 ? void 0 : _a.classList.add('preview__item-open');
                document.body.classList.add('is-scrollLock');
                focusToButton(this.DOM.content);
                window.addEventListener('keydown', this._focusHandle.bind(this), { capture: true });
                this.hiddenElements.forEach((element) => element.setAttribute('aria-hidden', 'true'));
                if (this.image) {
                    const tl = gsap.timeline();
                    tl.to(this.closeControl, { opacity: 1, duration: 0.5, ease: 'power4.in' }, 0)
                        .to(this.imageWrapper, { y: '0%', rotationX: 0, duration: 0.5, ease: 'power3.out' }, 0)
                        .to(this.image, { y: '0%', duration: 0.5, ease: 'power3.out' }, 0)
                        .fromTo(this.title, { y: 30 }, { y: 0, opacity: 1, duration: 0.5, ease: 'power1.out' }, 0)
                        .fromTo(this.genre, { x: -30 }, { x: 0, opacity: 0.8, duration: 1, ease: 'power1.out' }, 0)
                        .fromTo(this.members, { x: 30 }, { x: 0, opacity: 1, duration: 1, ease: 'power1.out' }, 0);
                }
                else if (this.video) {
                    const tl = gsap.timeline();
                    tl.to(this.closeControl, { opacity: 1, duration: 0.5, ease: 'power4.in' }, 0)
                        .to(this.imageWrapper, { y: '0%', rotationX: 0, duration: 0.5, ease: 'power3.out' }, 0)
                        .to(this.video, { y: '0%', duration: 0.5, ease: 'power3.out' }, 0)
                        .fromTo(this.title, { y: 30 }, { y: 0, opacity: 1, duration: 0.5, ease: 'power1.out' }, 0)
                        .fromTo(this.genre, { x: -30 }, { x: 0, opacity: 0.8, duration: 1, ease: 'power1.out' }, 0)
                        .fromTo(this.members, { x: 30 }, { x: 0, opacity: 1, duration: 1, ease: 'power1.out' }, 0);
                }
            } });
    }
    _showArtwork() {
        const showTl = gsap.timeline();
        showTl.to(this.DOM.content, { onComplete: () => {
                var _a;
                (_a = this.DOM.content) === null || _a === void 0 ? void 0 : _a.classList.add('preview__item-open');
                document.body.classList.add('is-scrollLock');
                focusToButton(this.DOM.content);
                window.addEventListener('keydown', this._focusHandle.bind(this), { capture: true });
                this.hiddenElements.forEach((element) => element.setAttribute('aria-hidden', 'true'));
                const tl = gsap.timeline();
                tl.to(this.closeControl, { opacity: 1, duration: 0.5, ease: 'power4.in' }, 0)
                    .to(this.imageWrapper, { y: '0%', rotationX: 0, duration: 0.5, ease: 'power3.out' }, 0)
                    .to(this.image, { y: '0%', duration: 0.5, ease: 'power3.out' }, 0)
                    .fromTo(this.title, { y: 30 }, { y: 0, opacity: 1, duration: 0.5, ease: 'power1.out' }, 0)
                    .fromTo(this.genre, { x: -30 }, { x: 0, opacity: 0.8, duration: 1, ease: 'power1.out' }, 0)
                    .fromTo(this.members, { x: 30 }, { x: 0, opacity: 1, duration: 1, ease: 'power1.out' }, 0);
            } });
    }
    _showContactForm() {
        const showTl = gsap.timeline();
        showTl.to(this.DOM.content, { onComplete: () => {
                var _a;
                (_a = this.DOM.content) === null || _a === void 0 ? void 0 : _a.classList.add('preview__item-open');
                document.body.classList.add('is-scrollLock');
                focusToButton(this.DOM.content);
                window.addEventListener('keydown', this._focusHandle.bind(this), { capture: true });
                this.hiddenElements.forEach((element) => element.setAttribute('aria-hidden', 'true'));
                const tl = gsap.timeline();
                tl.to(this.closeControl, { opacity: 1, duration: 1, ease: 'power1.out' }, 0)
                    .fromTo(this.contactFormTitle, { opacity: 0, y: 30 }, { y: 0, opacity: 1, duration: 1, ease: 'power1.out' }, 0)
                    .fromTo(this.contactFormForm, { opacity: 0, x: -20 }, { x: 0, opacity: 1, duration: 1, delay: 0.2, ease: 'power1.out' }, 0);
            } });
    }
    _restartAnimation(element) {
        element.style.animationName = "none";
        requestAnimationFrame(() => {
            element.style.animationName = "";
        });
    }
    _closeContent() {
        var _a;
        this._restartAnimation(this.loopTop1);
        this._restartAnimation(this.loopTop2);
        this._restartAnimation(this.loopBottom1);
        this._restartAnimation(this.loopBottom2);
        gsap.to(this.movieGridWrapper, { duration: 0.5, opacity: 1, y: 0, ease: 'power4.out' });
        (_a = this.DOM.content) === null || _a === void 0 ? void 0 : _a.classList.remove('preview__item-open');
        document.body.classList.remove('is-scrollLock');
        window.removeEventListener('keydown', this._focusHandle, { capture: true });
        this.hiddenElements.forEach((element) => element.removeAttribute('aria-hidden'));
        if (this.image) {
            const tl = gsap.timeline();
            tl.to(this.closeControl, { opacity: 0, duration: 0.5 })
                .to(this.imageWrapper, { y: '100%', rotationX: -20, duration: 0.5 }, 0)
                .to(this.image, { y: '-100%', duration: 0.5, ease: 'power4.in' }, 0)
                .to(this.title, { opacity: 0, duration: 0.5, ease: 'power4.in' }, 0)
                .to(this.genre, { opacity: 0, duration: 0.5, ease: 'power4.in' }, 0)
                .to(this.members, { opacity: 0, duration: 0.5, ease: 'power4.in' }, 0);
        }
        else if (this.video) {
            const tl = gsap.timeline();
            tl.to(this.closeControl, { opacity: 0, duration: 0.5 })
                .to(this.imageWrapper, { y: '100%', rotationX: -20, duration: 0.5 }, 0)
                .to(this.video, { y: '-100%', duration: 0.5, ease: 'power4.in' }, 0)
                .to(this.title, { opacity: 0, duration: 0.5, ease: 'power4.in' }, 0)
                .to(this.genre, { opacity: 0, duration: 0.5, ease: 'power4.in' }, 0)
                .to(this.members, { opacity: 0, duration: 0.5, ease: 'power4.in' }, 0);
        }
        this.DOM.el.focus();
    }
}
