export default class MovieControl {
    constructor() {
        this.isPlaying = false;
        this.anchors = document.querySelectorAll('.js-image-float');
        this.init();
    }
    init() {
        this.anchors.forEach((anchor) => {
            const video = anchor.querySelector('.js-videoThumbnail');
            if (video) {
                anchor.addEventListener('mouseenter', () => {
                    video.play();
                    this.isPlaying = true;
                });
                anchor.addEventListener('mouseout', () => {
                    if (!video.paused && this.isPlaying === true) {
                        video.pause();
                        video.currentTime = 0;
                        this.isPlaying = false;
                    }
                });
            }
        });
    }
}
