import Pace from 'pace-js';
import gsap from 'gsap';
import ImageFloat from './ImageFloat';
export default class Loading {
    constructor() {
        this.mediaQuery = window.matchMedia('(max-width: 34.375rem)');
        this.images = document.querySelectorAll('.js-image-float');
        this.imageFloat = new ImageFloat();
        Pace.options.target = '#top';
        this.setPace();
        gsap.config({
            nullTargetWarn: false,
        });
    }
    isTouchDevice() {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0));
    }
    setPace() {
        if (this.mediaQuery.matches) {
            addEventListener('visibilitychange', () => {
                window.scrollTo(0, 0);
            });
        }
        else {
            window.onbeforeunload = () => {
                window.scrollTo(0, 0);
            };
        }
        const logo = document.querySelector('.header__logo');
        if (this.mediaQuery.matches) {
            Pace.on('done', () => {
                const tl = gsap.timeline();
                setTimeout(() => {
                    tl.to(logo, {
                        top: '24px',
                        right: '16px',
                        transform: 'translate(0, 0)',
                        opacity: 1,
                        scale: 1,
                        duration: 1,
                        ease: 'power1.easeIn'
                    });
                }, 500);
            });
        }
        else {
            Pace.on('done', () => {
                const tl = gsap.timeline();
                setTimeout(() => {
                    tl.to(logo, {
                        top: '40px',
                        right: '40px',
                        transform: 'translate(0, 0)',
                        opacity: 1,
                        scale: 1,
                        duration: 1,
                        ease: 'power1.easeIn'
                    });
                }, 800);
            });
        }
        Pace.start({
            ajax: false,
            document: false
        });
        const preloader = document.getElementById('loading');
        const pace = document.querySelector('.pace');
        Pace.once('done', () => {
            const tl = gsap.timeline();
            tl.to(preloader, {
                autoAlpha: 0,
                duration: 1.5,
                ease: "power1.inOut",
                onComplete: () => {
                    preloader.classList.add('loaded');
                }
            }, 0)
                .to(pace, {
                autoAlpha: 0,
                duration: 1.5,
                ease: "power1.inOut",
                onStart: () => {
                    this._imageAnimation();
                }
            }, 0);
        });
    }
    _imageAnimation() {
        const tl = gsap.timeline();
        const imageStart = 300;
        this.duration = 3.5;
        this.images.forEach(image => {
            if (image.offsetLeft < (window.innerWidth - (image.clientWidth / 2)) / 2 && image.offsetTop > (window.innerHeight - (image.clientHeight / 2)) / 2) {
                tl.from(image, {
                    x: Math.random() * -imageStart,
                    y: Math.random() * imageStart,
                    duration: this.duration,
                    autoAlpha: 0,
                    ease: "power3.inOut",
                    onComplete: () => {
                        if (this.isTouchDevice()) {
                            window.addEventListener("touchmove", () => {
                                this.imageFloat.on = true;
                            }, { once: true });
                        }
                        else {
                            setTimeout(() => {
                                this.imageFloat.on = true;
                            }, 200);
                        }
                    }
                }, 0);
            }
            else if (image.offsetLeft < (window.innerWidth - (image.clientWidth / 2)) / 2 && image.offsetTop < (window.innerHeight - (image.clientHeight / 2)) / 2) {
                tl.from(image, {
                    x: Math.random() * -imageStart,
                    y: Math.random() * -imageStart,
                    duration: this.duration,
                    autoAlpha: 0,
                    ease: "power3.inOut",
                    onComplete: () => {
                        if (this.isTouchDevice()) {
                            window.addEventListener("touchmove", () => {
                                this.imageFloat.on = true;
                            }, { once: true });
                        }
                        else {
                            setTimeout(() => {
                                this.imageFloat.on = true;
                            }, 200);
                        }
                    }
                }, 0);
            }
            else if (image.offsetLeft > (window.innerWidth - (image.clientWidth / 2)) / 2 && image.offsetTop > (window.innerHeight - (image.clientHeight / 2)) / 2) {
                tl.from(image, {
                    x: Math.random() * imageStart,
                    y: Math.random() * imageStart,
                    duration: this.duration,
                    autoAlpha: 0,
                    ease: "power3.inOut",
                    onComplete: () => {
                        if (this.isTouchDevice()) {
                            window.addEventListener("touchmove", () => {
                                this.imageFloat.on = true;
                            }, { once: true });
                        }
                        else {
                            setTimeout(() => {
                                this.imageFloat.on = true;
                            }, 200);
                        }
                    }
                }, 0);
            }
            else {
                tl.from(image, {
                    x: Math.random() * imageStart,
                    y: Math.random() * -imageStart,
                    duration: this.duration,
                    autoAlpha: 0,
                    ease: "power3.inOut",
                    onComplete: () => {
                        if (this.isTouchDevice()) {
                            window.addEventListener("touchmove", () => {
                                this.imageFloat.on = true;
                            }, { once: true });
                        }
                        else {
                            setTimeout(() => {
                                this.imageFloat.on = true;
                            }, 200);
                        }
                    }
                }, 0);
            }
        });
    }
    update() {
        this.imageFloat.update();
    }
}
