import * as THREE from 'three'
import Experience from './Experience.js'

import ParticleBox from './ParticleBox.js'

export default class World
{
    constructor(_options)
    {
        this.experience = new Experience()
        this.config = this.experience.config
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        
        this.resources.on('groupEnd', (_group) =>
        {
            if(_group.name === 'base')
            {
                // this.setDummy()
                this.setParticleBox()
            }
        })
    }

    // setDummy()
    // {
    //     const cube = new THREE.Mesh(
    //         new THREE.BoxGeometry(1, 1, 1),
    //         new THREE.MeshBasicMaterial({ map: this.resources.items.lennaTexture })
    //     )
    //     this.scene.add(cube)        
    // }

    setParticleBox()
    {
        this.particleBox = new ParticleBox()
    }

    resize()
    {
    }

    update(elapsed)
    {
        if(this.particleBox)
        {
            this.particleBox.update(elapsed)
        }
    }

    destroy()
    {
    }
}