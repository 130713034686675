import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
export default class Text {
    constructor() {
        this.setMissionScrollTrigger();
        this.setAboutScrollTrigger();
        this.setPartnersScrollTrigger();
        this.setContactScrollTrigger();
    }
    setMissionScrollTrigger() {
        const missionSection = document.querySelector('.js-mission');
        const borderLeft = document.querySelector('.js-mission-border-left');
        const borderBottom = document.querySelector('.js-mission-border-bottom');
        const title = document.querySelector('.js-mission-title-main');
        const caption = document.querySelector('.js-mission-title-caption');
        const subTitle = document.querySelector('.js-mission-title-sub');
        const missionMessage = document.querySelector('.js-mission-message');
        const missionDescription = document.querySelector('.js-mission-description');
        const missionTl = gsap.timeline({
            scrollTrigger: {
                trigger: missionSection,
                start: 'top-=260px top',
                end: 'bottom center',
                toggleActions: 'play none play none',
            },
            ease: "Expo.easeOut"
        });
        missionTl.from(borderLeft, {
            y: '-100%',
            duration: 1,
            ease: "Power2.easeIn"
        }, 'start')
            .from(title, {
            x: '-100%',
            duration: 1,
            ease: "Expo.easeOut"
        }, 'start')
            .from(borderBottom, {
            width: '0%',
            duration: 0.9,
            ease: "Expo.easeOut"
        }, 'second')
            .from(caption, {
            y: '110%',
            duration: 1,
            ease: "Expo.easeOut"
        }, 'second')
            .from(subTitle, {
            y: '110%',
            duration: 1.7,
            ease: "Expo.easeOut"
        }, 'second')
            .from(missionMessage, {
            y: '10%',
            autoAlpha: 0,
            duration: 2.2,
            ease: "Expo.easeOut"
        }, 'second')
            .from(missionDescription, {
            y: '10%',
            autoAlpha: 0,
            duration: 2.5,
            ease: "Expo.easeOut"
        }, 'second');
    }
    setAboutScrollTrigger() {
        const aboutSection = document.querySelector('.js-about');
        const borderRight = document.querySelector('.js-about-border-right');
        const borderBottom = document.querySelector('.js-about-border-bottom');
        const title = document.querySelector('.js-about-title-main');
        const caption = document.querySelector('.js-about-title-caption');
        const aboutList = document.querySelector('.js-about-list');
        const aboutMembers = document.querySelector('.js-about-members');
        const aboutTl = gsap.timeline({
            scrollTrigger: {
                trigger: aboutSection,
                start: 'top-=260px top',
                end: 'bottom center',
                toggleActions: 'play none play none',
            },
            ease: "Expo.easeOut"
        });
        aboutTl.from(borderRight, {
            y: '-100%',
            duration: 1,
            ease: "Power2.easeIn"
        }, 'start')
            .from(title, {
            x: '100%',
            duration: 1,
            ease: "Expo.easeOut"
        }, 'start')
            .from(borderBottom, {
            x: '100%',
            duration: 0.9,
            ease: "Expo.easeOut"
        }, 'second')
            .from(caption, {
            y: '110%',
            duration: 1,
            ease: "Expo.easeOut"
        }, 'second')
            .from(aboutList, {
            y: '10%',
            autoAlpha: 0,
            duration: 2.2,
            ease: "Expo.easeOut"
        }, 'second')
            .from(aboutMembers, {
            y: '10%',
            autoAlpha: 0,
            duration: 2.5,
            ease: "Expo.easeOut"
        }, 'second');
    }
    setPartnersScrollTrigger() {
        const partnersSection = document.querySelector('.js-partners');
        const title = document.querySelector('.js-partners-title');
        const partnersTl = gsap.timeline({
            scrollTrigger: {
                trigger: partnersSection,
                start: 'top-=260px center',
                end: 'bottom center',
                toggleActions: 'play none play none',
            },
        });
        partnersTl.from(title, {
            y: '110%',
            duration: 2,
            ease: "Expo.easeOut"
        });
    }
    setContactScrollTrigger() {
        const contactSection = document.querySelector('.js-contact');
        const title = document.querySelector('.js-contact-title');
        const caption = document.querySelector('.js-contact-caption');
        const contactTl = gsap.timeline({
            scrollTrigger: {
                trigger: contactSection,
                start: 'top-=260px center',
                end: 'bottom center',
                toggleActions: 'play none play none',
            },
        });
        contactTl.from(title, {
            y: '110%',
            duration: 2,
            ease: "Expo.easeOut"
        }, 0)
            .from(caption, {
            y: '110%',
            duration: 2,
            ease: "Expo.easeOut"
        }, 0);
    }
}
