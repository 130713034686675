import * as THREE from 'three';
import gsap from 'gsap'
import Experience from './Experience.js'
// import { Tween1 } from '../ts/_utils/Tween'
// import Pointer from '../ts/_utils/Pointer'

export default class ParticleBox {
    constructor() {
        this.mediaQuery = window.matchMedia('(max-width: 34.375rem)')
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.renderer = this.experience.renderer
        this.camera = this.experience.camera
        this.maxParticleCount = 1000
        this.particleCount = 1
        this.particlesData = []
        this.r = 800
        this.pointCloud = null
        this.group = new THREE.Group()
        this.lineMesh = null

        this._options = {
            minDistance: 145,
            limitConnections: false,
            maxConnections: 5,
            particleCount: this.particleCount
        }

        this.init()
        this.setScrollTrigger()
        this.animate()
    }

    init()
    {

        const segments = this.maxParticleCount * this.maxParticleCount
        this.positions = new Float32Array(segments * 3)
        this.colors = new Float32Array(segments * 3)

        const pointMaterial = new THREE.PointsMaterial({
            color: 0xFFFFFF,
            size: 3,
            blending: THREE.AdditiveBlending,
            transparent: true,
            sizeAttenuation: false
        })

        this.particles = new THREE.BufferGeometry()
        this.particlePositions = new Float32Array(this.maxParticleCount * 3)

        for (let i = 0; i < this.maxParticleCount; i++) {
            const x = Math.random() * this.r - this.r / 2
            const y = Math.random() * this.r - this.r / 2
            const z = Math.random() * this.r - this.r / 2

            this.particlePositions[i * 3] = x
            this.particlePositions[i * 3 + 1] = y
            this.particlePositions[i * 3 + 2] = z

            this.particlesData.push({
                velocity: new THREE.Vector3(-1 + Math.random() * 2, -1 + Math.random() * 2, -1 + Math.random() * 2),
                numConnections : 0,
            })
        }

        this.particles.setDrawRange(0, this.particleCount)
        this.particles.setAttribute('position', new THREE.BufferAttribute(this.particlePositions, 3).setUsage( THREE.DynamicDrawUsage))
        this.pointCloud = new THREE.Points(this.particles, pointMaterial)

        this.group.add(this.pointCloud)

        const geometry = new THREE.BufferGeometry()

        geometry.setAttribute('position', new THREE.BufferAttribute(this.positions, 3).setUsage( THREE.DynamicDrawUsage))
        geometry.setAttribute('color', new THREE.BufferAttribute(this.colors, 3).setUsage( THREE.DynamicDrawUsage))

        geometry.computeBoundingSphere()

        geometry.setDrawRange( 0, 0)

        const material = new THREE.LineBasicMaterial({
            vertexColors: true,
            blending: THREE.AdditiveBlending,
            transparent: true,
        })

        this.lineMesh = new THREE.LineSegments(geometry, material)
        this.group.add(this.lineMesh)

        this.scene.add(this.group)
    }

    setScrollTrigger() {
        const sectionMission = document.getElementById('mission')

        if(this.mediaQuery.matches) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: sectionMission,
                    start: 'top center',
                    end: 'center center',
                    scrub: 2.4,
                }
            })
            tl.to(this, {particleCount: "600"})
        } else {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: sectionMission,
                    start: 'top center',
                    end: 'bottom center',
                    scrub: 2.4,
                }
            })
            tl.to(this, {particleCount: "1000"})
        }
    }

    animate()
    {
        let vertexpos = 0
        let colorpos = 0
        let numConnected = 0
        for(let i = 0; i < this.particleCount; i++){
            this.particlesData[i].numConnections = 0
        }

        for (let i = 0; i < this.particleCount; i++){
            const particleData = this.particlesData[i]

            this.particlePositions[i * 3] += particleData.velocity.x
            this.particlePositions[i * 3 + 1] += particleData.velocity.y
            this.particlePositions[i * 3 + 2] += particleData.velocity.z

            if(this.particlePositions[ i * 3 ] < -this.r / 2 || this.particlePositions[ i * 3 ] > this.r / 2){
                particleData.velocity.x = -particleData.velocity.x
            }
            
            if(this.particlePositions[ i * 3 + 1] < -this.r / 2 || this.particlePositions[ i * 3 + 1] > this.r / 2){
                particleData.velocity.y = -particleData.velocity.y
            }
            
            if(this.particlePositions[ i * 3 + 2] < -this.r / 2 || this.particlePositions[ i * 3 + 2] > this.r / 2){
                particleData.velocity.z = -particleData.velocity.z
            }

            if(this._options.limitConnections && particleData.numConnections >= this._options.maxConnections){
                continue
            }

            for (let j = i + 1; j < this.particleCount; j ++) {
                const particleDataB = this.particlesData[j]

                if(this._options.limitConnections && particleDataB.numConnections >= this._options.maxConnections){
                    continue
                }

                const dx = this.particlePositions[i * 3] - this.particlePositions[j * 3]
                const dy = this.particlePositions[i * 3 + 1] - this.particlePositions[j * 3 + 1]
                const dz = this.particlePositions[i * 3 + 2] - this.particlePositions[j * 3 + 2]

                const dist = Math.sqrt(dx * dx + dy * dy + dz * dz)

                if(dist < this._options.minDistance){
                    particleData.numConnections ++
                    particleDataB.numConnections ++

                    this.positions[vertexpos++] = this.particlePositions[i * 3]
                    this.positions[vertexpos++] = this.particlePositions[i * 3 + 1]
                    this.positions[vertexpos++] = this.particlePositions[i * 3 + 2]

                    this.positions[vertexpos++] = this.particlePositions[j * 3]
                    this.positions[vertexpos++] = this.particlePositions[j * 3 + 1]
                    this.positions[vertexpos++] = this.particlePositions[j * 3 + 2]

                    const alpha = 1.0 - dist / this._options.minDistance

                    this.colors[colorpos++] = alpha
                    this.colors[colorpos++] = alpha
                    this.colors[colorpos++] = alpha

                    this.colors[colorpos++] = alpha
                    this.colors[colorpos++] = alpha
                    this.colors[colorpos++] = alpha

                    numConnected ++
                }
            }
        }

        this.lineMesh.geometry.setDrawRange(0, numConnected * 2)
        this.lineMesh.geometry.attributes.position.needsUpdate = true
        this.lineMesh.geometry.attributes.color.needsUpdate = true

        this.pointCloud.geometry.attributes.position.needsUpdate = true
    }

    // _map(x, a, b, c, d) {
    //     return c + (x - a) * (d - c) / (b - a)
    // }

    update(elapsed)
    {
        this.animate() 
        this.group.rotation.y = elapsed * 0.00025
        this.group.rotation.z = elapsed * 0.00008
        this.group.rotation.x = elapsed * 0.00008
        this.renderer.instance.render(this.scene, this.camera.instance)
    }
}