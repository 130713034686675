import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
export default class Menu {
    constructor() {
        this.mediaQuery = window.matchMedia('(max-width: 34.375rem)');
        this.init();
    }
    init() {
        this.items = document.querySelectorAll('.js-menu-item');
        this.scrollIcon = document.querySelector('.js-scroll-down');
        if (!this.mediaQuery.matches) {
            this.setHover();
        }
        else {
            this.addMobileMenu();
        }
        this.setActive();
    }
    setHover() {
        gsap.defaults({ duration: 0.3 });
        this.items.forEach((item) => {
            const tl = gsap.timeline({ paused: true });
            tl.to(item, {
                x: 10,
                scale: 1.2,
                opacity: 1,
                transformOrigin: "left center",
                ease: "Expo.easeOut"
            });
            item.addEventListener("mouseenter", () => {
                if (!item.classList.contains('is-active')) {
                    tl.play();
                }
            });
            item.addEventListener("mouseleave", () => {
                if (!item.classList.contains('is-active')) {
                    {
                        tl.reverse();
                    }
                }
            });
        });
    }
    setActive() {
        const items = document.querySelectorAll('.js-menu-item');
        const list = document.querySelector('.side-navigation__list');
        const closeIcon = document.querySelector('.side-navigation__closeImage');
        const openIcon = document.querySelector('.js-side-menu-icon');
        const tlDuration = 0.3;
        const classes = ['is-black'];
        const toggleClasses = (element) => {
            classes.forEach((myClass) => {
                element.classList.toggle(myClass);
            });
        };
        const movieSection = document.querySelector('.js-movie');
        const movieMenu = document.querySelector('.js-menu-item-movie');
        const movieTl = gsap.timeline({
            scrollTrigger: {
                trigger: movieSection,
                start: 'top-=260px top',
                end: 'bottom center',
                toggleActions: 'play reverse play reverse',
                toggleClass: { targets: movieMenu, className: 'is-active', }
            },
            ease: "Expo.easeOut"
        });
        movieTl.to(movieMenu, {
            x: 5,
            scale: 1.2,
            letterSpacing: '0.05em',
            opacity: 1,
            duration: tlDuration,
            transformOrigin: "left center",
        })
            .to('.movie__border', {
            width: '8px',
            backgroundColor: '#fff',
            opacity: 1,
            duration: tlDuration,
        }, 0);
        const artworkSection = document.querySelector('.js-artwork');
        const artworkMenu = document.querySelector('.js-menu-item-artwork');
        const artworkTl = gsap.timeline({
            scrollTrigger: {
                trigger: artworkSection,
                start: 'top-=260px top',
                end: 'bottom center',
                toggleActions: 'play reverse play reverse',
                toggleClass: { targets: artworkMenu, className: 'is-active' },
                onEnter: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
                onLeaveBack: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
                onLeave: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
                onEnterBack: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
            },
            ease: "Expo.easeOut"
        });
        artworkTl
            .to(artworkMenu, {
            x: 5,
            scale: 1.2,
            opacity: 1,
            duration: tlDuration,
            transformOrigin: "left center",
        })
            .to('.artwork__border', {
            width: '8px',
            backgroundColor: '#000',
            opacity: 1,
            duration: tlDuration,
        }, 0);
        const missionSection = document.querySelector('.js-mission');
        const missionMenu = document.querySelector('.js-menu-item-mission');
        const missionTl = gsap.timeline({
            scrollTrigger: {
                trigger: missionSection,
                start: 'top-=260px top',
                end: 'bottom center',
                toggleActions: 'play reverse play reverse',
                toggleClass: { targets: missionMenu, className: 'is-active' },
            },
            ease: "Expo.easeOut"
        });
        missionTl.to(missionMenu, {
            x: 5,
            scale: 1.2,
            opacity: 1,
            duration: tlDuration,
            transformOrigin: "left center",
        })
            .to('.mission__border', {
            width: '8px',
            opacity: 1,
            duration: tlDuration,
        }, 0);
        const aboutSection = document.querySelector('.js-about');
        const aboutMenu = document.querySelector('.js-menu-item-about');
        const aboutTl = gsap.timeline({
            scrollTrigger: {
                trigger: aboutSection,
                start: 'top-=260px top',
                end: 'bottom center',
                toggleActions: 'play reverse play reverse',
                toggleClass: { targets: aboutMenu, className: 'is-active' },
                onEnter: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
                onLeaveBack: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
                onLeave: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
                onEnterBack: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
            },
            ease: "Expo.easeOut"
        });
        aboutTl.to(aboutMenu, {
            x: 5,
            scale: 1.2,
            opacity: 1,
            duration: tlDuration,
            transformOrigin: "left center",
        })
            .to('.about__border', {
            width: '8px',
            backgroundColor: '#000',
            opacity: 1,
            duration: tlDuration,
        }, 0);
        const contactSection = document.querySelector('.js-contact');
        const contactMenu = document.querySelector('.js-menu-item-contact');
        const contactTl = gsap.timeline({
            scrollTrigger: {
                trigger: contactSection,
                start: 'top center',
                end: 'bottom center',
                toggleActions: 'play reverse play reverse',
                toggleClass: { targets: contactMenu, className: 'is-active' },
                onEnter: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
                onLeaveBack: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
                onLeave: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
                onEnterBack: () => {
                    toggleClasses(list);
                    if (!this.mediaQuery.matches) {
                        toggleClasses(closeIcon);
                        toggleClasses(openIcon);
                    }
                },
            },
            ease: "Expo.easeOut"
        });
        contactTl.to(contactMenu, {
            x: 5,
            scale: 1.2,
            opacity: 1,
            duration: tlDuration,
            transformOrigin: "left center",
        })
            .to('.contact__border', {
            width: '8px',
            backgroundColor: '#000',
            opacity: 1,
            duration: tlDuration,
        }, 0)
            .to(this.scrollIcon, {
            opacity: 0,
            duration: 0.5
        }, 0);
    }
    addMobileMenu() {
        const menuIcon = document.querySelector('.js-side-menu-icon');
        const sideNavigation = document.querySelector('.js-side-navigation');
        const closeIcon = document.querySelector('.js-side-menu-close-icon');
        menuIcon.addEventListener('click', () => {
            const tl = gsap.timeline();
            tl.to(menuIcon, { duration: 0.3, left: '-50px', ease: "Expo.easeIn" })
                .to(sideNavigation, { duration: 0.6, left: '4%', ease: "Expo.easeOut" });
        });
        this.items.forEach(item => {
            item.addEventListener('click', () => {
                const tl = gsap.timeline();
                tl.to(menuIcon, { duration: 0.3, left: '16px', ease: "Expo.easeIn" })
                    .to(sideNavigation, { duration: 0.6, left: '-100%', ease: "Expo.easeOut" });
            });
        });
        closeIcon.addEventListener('click', () => {
            const tl = gsap.timeline();
            tl.to(menuIcon, { duration: 0.3, left: '16px', ease: "Expo.easeIn" })
                .to(sideNavigation, { duration: 0.6, left: '-100%', ease: "Expo.easeOut" });
        });
    }
}
