const INTERACTIVE_SELECTOR = 'button, a';
const createInteractiveElArray = (element) => {
    const elements = element.querySelectorAll(INTERACTIVE_SELECTOR);
    const interactiveElArray = Array.from(elements);
    return interactiveElArray;
};
export const focusToButton = (parentElement, isFirstFocus = true) => {
    if (!parentElement) {
        throw new Error('要素が見つかりませんでした');
    }
    const focusableArray = createInteractiveElArray(parentElement);
    if (focusableArray.length > 0) {
        focusableArray[isFirstFocus ? 0 : focusableArray.length - 1].focus();
    }
    focusableArray[0].focus();
};
export const modalFocus = (event, parentElement, onClose) => {
    if (!parentElement) {
        return;
    }
    switch (event.code) {
        case 'Enter':
        case 'Space':
            break;
        case 'Escape':
            onClose();
        case 'Tab': {
            const interactiveElArray = createInteractiveElArray(parentElement);
            const focusIndex = interactiveElArray.findIndex(el => el === document.activeElement);
            if (interactiveElArray.length === 1) {
                event.preventDefault();
                event.stopImmediatePropagation();
                focusToButton(parentElement, false);
                break;
            }
            if (focusIndex === 0) {
                if (event.shiftKey) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    focusToButton(parentElement, false);
                }
            }
            else if (focusIndex >= interactiveElArray.length - 1) {
                if (!event.shiftKey) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    focusToButton(parentElement, true);
                }
            }
            else if (focusIndex === -1) {
                focusToButton(parentElement, true);
            }
            break;
        }
    }
};
