import $ from 'jquery';
import gsap from 'gsap';
export default class ContactForm {
    constructor() {
        this.form = document.getElementById('form');
        this.closeButton = document.querySelector('.preview__item-close');
        this.success = document.querySelector('.js-success-message');
        this.error = document.querySelector('.js-error-message');
        this.init();
        this.setClose();
    }
    init() {
        gsap.set('.js-success-message', {
            autoAlpha: 0,
            x: '-10%'
        });
        gsap.set('.js-error-message', {
            autoAlpha: 0,
            x: '-10%'
        });
        $(() => {
            $('#form').submit((event) => {
                let formData = $('#form').serialize();
                $.ajax({
                    url: "https://docs.google.com/forms/u/0/d/e/1FAIpQLScCRLS-onB4xEV50iO2PPKxUd7gQKc8FLhOGxm2J4WrlfpEng/formResponse",
                    data: formData,
                    type: "POST",
                    dataType: "xml",
                    statusCode: {
                        0: () => {
                            this.form.reset();
                            const tl = gsap.timeline();
                            tl
                                .to('.js-success-message', {
                                autoAlpha: 1,
                                x: '0%',
                                duration: 2,
                                ease: 'power1.easeOut'
                            });
                        },
                        200: () => {
                            const tl = gsap.timeline();
                            tl.to('.js-error-message', {
                                autoAlpha: 1,
                                x: '0%',
                                duration: 2,
                                ease: 'power1.easeOut'
                            });
                        }
                    }
                });
                event.preventDefault();
            });
        });
    }
    setClose() {
        this.closeButton.addEventListener('click', () => {
            this.form.reset();
            if (this.success) {
                gsap.to(this.success, {
                    autoAlpha: 0,
                });
            }
            if (this.error) {
                gsap.to(this.error, {
                    autoAlpha: 0,
                });
            }
        });
    }
}
