import { gsap } from 'gsap';
import { Tween1 } from './Tween';
import Mouse from './Mouse';
export default class Cursor {
    constructor(el) {
        this.mouse = new Mouse();
        this.DOM = { el: el };
        this.bounds = this.DOM.el.getBoundingClientRect();
        this.DOM.el.style.opacity = '0';
        this.DOM.el.style.transform = 'translate3d(0, 0, 0)';
        this.cursorConfig = {
            x: { previous: 0, current: 0, amt: 0.2 },
            y: { previous: 0, current: 0, amt: 0.2 },
            scale: { previous: 1, current: 1, amt: 10 },
        };
        window.addEventListener('mousemove', this.onMouseMoveEvent.bind(this));
    }
    onMouseMoveEvent() {
        this.cursorConfig.x.previous = this.cursorConfig.x.current = this.mouse.x - this.bounds.width / 2;
        this.cursorConfig.y.previous = this.cursorConfig.y.current = this.mouse.y - this.bounds.height / 2;
        gsap.to(this.DOM.el, { duration: 0.5, ease: 'Power3.easeInOut', opacity: 1 });
        window.removeEventListener('mousemove', this.onMouseMoveEvent);
    }
    render() {
        this.cursorConfig.x.current = this.mouse.x - this.bounds.width / 2;
        this.cursorConfig.y.current = this.mouse.y - this.bounds.height / 2;
        for (const key in this.cursorConfig) {
            this.tween = new Tween1(this.cursorConfig[key].previous, 0.01);
            this.tween.update(this.cursorConfig[key].current, this.cursorConfig[key].amt);
            this.cursorConfig[key].previous = this.tween.x;
        }
        this.DOM.el.style.transform = `translateX(${this.cursorConfig.x.previous}px) translateY(${this.cursorConfig.y.previous}px) scale(${this.cursorConfig.scale.previous})`;
    }
    update() {
        this.render();
    }
    enter() {
        this.cursorConfig['scale'].current = 2;
    }
    leave() {
        this.cursorConfig['scale'].current = 1;
    }
}
