import gsap from 'gsap';
import Pointer from './_utils/Pointer';
import { Tween2 } from './_utils/Tween';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
export default class ImageFloat {
    constructor() {
        this.on = false;
        this.mediaQuery = window.matchMedia('(max-width: 34.375rem)');
        this.init();
        this.setValue();
    }
    init() {
        this.pointer = new Pointer();
        this.images = Array.from(document.querySelectorAll('.js-image-float'));
        this.section = document.getElementById('movie');
    }
    _map(x, a, b, c, d) {
        return c + (x - a) * (d - c) / (b - a);
    }
    setValue() {
        this.images.forEach((image) => {
            image.values = { x: 0, y: 0 };
            if (this.mediaQuery.matches) {
                image.xStart = 40;
                image.yStart = 24;
                this.tween = new Tween2(image.values.x, image.values.y, 0.05);
            }
            else {
                image.xStart = 400;
                image.yStart = 300;
                this.tween = new Tween2(image.values.x, image.values.y, 0.05);
            }
        });
    }
    render(image) {
        let { x, y } = image.values;
        let xStart = image.xStart;
        let yStart = image.yStart;
        let sectionHeight = this.section.getBoundingClientRect().height;
        let pointerX = this._map(this.pointer.x, 0, window.innerWidth, -xStart, xStart);
        let pointerY = this._map(this.pointer.y, 0, sectionHeight, -yStart, yStart);
        let imagePosition = image.getBoundingClientRect();
        let posX = imagePosition.x + window.scrollX;
        let posY = imagePosition.y + window.scrollY;
        let distance = Math.sqrt(Math.pow(this.pointer.x - posX, 2) + Math.pow(this.pointer.y - posY, 2));
        let scaleRate = this._map(distance, 0, window.innerWidth, 1.1, 0.8);
        this.tween.update({ x: pointerX, y: pointerY }, 0.07);
        x = -this.tween.position.x;
        y = -Math.min(this.tween.position.y, yStart);
        gsap.set(image, { x: x, y: y, scale: Math.max(0.7, scaleRate) });
    }
    renderMobile(image) {
        let { x, y } = image.values;
        let xStart = image.xStart;
        let yStart = image.yStart;
        let sectionHeight = this.section.getBoundingClientRect().height;
        let pointerX = this._map(this.pointer.x, 0, window.innerWidth, -xStart, xStart);
        let pointerY = this._map(this.pointer.y, 0, sectionHeight, -yStart, yStart);
        this.tween.update({ x: pointerX, y: pointerY }, 0.07);
        x = this.tween.position.x;
        y = -Math.min(this.tween.position.y, yStart);
        gsap.set(image, { x: x, y: y });
    }
    update() {
        if (this.on && !this.mediaQuery.matches) {
            {
                this.images.forEach((image) => {
                    if (image.values) {
                        this.render(image);
                    }
                });
            }
        }
        else if (this.on && this.mediaQuery.matches) {
            this.images.forEach((image) => {
                if (image.values) {
                    this.renderMobile(image);
                }
            });
        }
    }
}
