import { gsap } from 'gsap';
export default class Preview {
    constructor(el) {
        this.DOM = { el: el };
        this.DOM.closeControl = this.DOM.el.querySelector('.preview__item-close');
        this.DOM.imageWrapper = this.DOM.el.querySelector('.preview__imageWrapper ');
        if (this.DOM.imageWrapper) {
            this.DOM.image = this.DOM.imageWrapper.querySelector('.preview__image');
            this.DOM.video = this.DOM.imageWrapper.querySelector('.preview__video');
        }
        this.DOM.title = this.DOM.el.querySelector('.preview__title');
        this.DOM.genre = this.DOM.el.querySelector('.preview__genre');
        this.DOM.members = this.DOM.el.querySelector('.preview__members');
        this.init();
    }
    init() {
        if (this.DOM) {
            if (this.DOM.imageWrapper) {
                gsap.set(this.DOM.imageWrapper, { y: '100%', rotationX: -20 });
            }
            if (this.DOM.image) {
                gsap.set(this.DOM.image, { y: '-100%' });
            }
            else if (this.DOM.video) {
                gsap.set(this.DOM.video, { y: '-100%' });
            }
            gsap.set(this.DOM.closeControl, { opacity: 0 });
            if (this.DOM.title) {
                gsap.set(this.DOM.title, { opacity: 0 });
            }
            if (this.DOM.genre) {
                gsap.set(this.DOM.genre, { opacity: 0 });
            }
            if (this.DOM.members) {
                gsap.set(this.DOM.members, { opacity: 0 });
            }
        }
    }
}
